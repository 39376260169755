<template>
  <div>
    <div class="header">
      <el-select
        v-model="ids"
        filterable=""
        multiple
        class="u-right-margin--2x"
        placeholder="Select User Group"
        style="width:30%"
      >
        <el-option
          v-for="item in lookups.userGroups"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="default" @click="tryIt">Try It</el-button>
    </div>
    <div>{{ users.length }} Result</div>
    <div class="body-container">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0" v-for="user in users" :key="user.id">
          <el-card
            shadow=" hover"
            class="u-bottom-margin--2x "
            :body-style="{ padding: '20px' }"
          >
            <div slot="header">
              <span>{{ user.name }}</span>
            </div>
            user ID :{{ user.id }}<br />

            Store name :{{ user.storeName }} <br />
            phone :{{ user.phone }} <br />
            address :{{ user.address }}<br />
            area Id :{{ user.areaId }} <br
          /></el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { userGroupService } from "../../services/userGroup.service";

export default {
  data() {
    return {
      ids: [],
      users: [],
    };
  },
  computed: {
    ...mapGetters({
      lookups: "getAllLookups",
    }),
  },
  methods: {
    tryIt() {
      this.$loading();
      userGroupService.getUsers({ ids: this.ids }).then((res) => {
        this.$loading().close();
        this.users = res.userGroups;
      });
    },
  },
};
</script>

<style></style>
